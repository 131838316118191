/* eslint-disable */
import React, { FC } from 'react';
import { Card, Col, Row, Tooltip } from 'antd';
import { useSelector } from 'react-redux';

import { selectGeneralData } from 'redux/reducers/dashboard/dashboard.slice';
import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';
import { RootState } from 'redux/store.types';
import { formatNumber } from 'components/App/app.hooks';

import { cardConfigData, TooltipeText } from '../config';
import classes from '../Dashboard.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { selectIsTooltipeActive } from 'redux/reducers/serverConfigs/serverConfigs.slice';

interface CustomCardTitleProps {
  index: number;
}

const CustomCardTitle: FC<CustomCardTitleProps> = ({ index }) => {
  const { title } = cardConfigData[index];

  const isSmallScreen = () => {
    return window.innerWidth <= 636;
  };

  const formatTitle = (title: string) => {
    if (isSmallScreen()) {
      return title
        .split(' ')
        .map((word, wordIndex) => (
          <div key={`word-${index}-${wordIndex}`}>{word}</div>
        ));
    }
    return title;
  };

  return <div className={classes.customCardTitle}>{formatTitle(title)}</div>;
};

interface CardProps {
  isMobile: boolean;
}

const GeneralInfo: FC<CardProps> = ({ isMobile }) => {
  const generalData = useSelector(selectGeneralData);
  const isLoading = useSelector<RootState, boolean>(
    state => state.dashboard.isLoading,
  );
  const isTooltipeActive = useSelector(selectIsTooltipeActive);

  const responsiveConfig = {
    xs: 12,
    sm: 12,
    md: 8,
    lg: 6,
    xl: 4.5,
  };

  const sortedCardConfigData = cardConfigData.sort(
    (a: any, b: any) => (a.priority || 0) - (b.priority || 0),
  );

  const cards = sortedCardConfigData.map((config, index) => {
    const key = config.key as keyof typeof generalData;
    const value = generalData[key];
    const hasData = !!value;
    let content;

    if (hasData) {
      content = value;
      return (
        <Col key={config.key} {...responsiveConfig}>
          <Card
            className={`${classes.generalCards} ${
              !hasData ? classes.disabledCard : ''
            }`}
            title={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <CustomCardTitle index={index} />
                &nbsp;&nbsp;
                {isTooltipeActive && config.tooltipeText && (
                  <Tooltip
                    className={classes.tooltipeIcon}
                    title={config.tooltipeText}
                    placement='topLeft'>
                    <span>
                      <InfoCircleOutlined style={{ fontSize: 15 }} />
                    </span>
                  </Tooltip>
                )}
              </div>
            }
            bordered={false}>
            {formatNumber(content)}
          </Card>
        </Col>
      );
    }
    return (
      <Col key={config.key} {...responsiveConfig}>
        <Card
          className={`${classes.generalCards} ${
            !hasData ? classes.disabledCard : ''
          }`}
          title={<CustomCardTitle index={index} />}
          bordered={false}>
          N/A
        </Card>
      </Col>
    );
  });

  if (isLoading) {
    return <GlobalLoader />;
  }

  return (
    <>
      {!isMobile && (
        <h1 className={classes.infoTitle}>
          General Information
          {isTooltipeActive && (
            <Tooltip
              style={{ cursor: 'pointer' }}
              title={TooltipeText.Dashboard}
              placement='topLeft'>
              <span>
                <InfoCircleOutlined
                  style={{ fontSize: 15, marginLeft: 6, position: 'absolute' }}
                />
              </span>
            </Tooltip>
          )}
        </h1>
      )}
      <div className={classes.generalInfoSection}>
        <Row gutter={10}>{cards}</Row>
      </div>
    </>
  );
};

export default GeneralInfo;
