import React, { FC, useState, useRef, useEffect } from 'react';
import { Button, DatePicker, Select } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { fetchAllData } from 'redux/reducers/dashboard/dashboard.thunks';
import { AppDispatch } from 'redux/store.types';
import { selectIsMenuSidebarOpen } from 'redux/reducers/appConfigs/appConfigs.slice';
import {
  changeCurrency,
  selectAuthUserInfo,
} from 'redux/reducers/serverConfigs/serverConfigs.slice';

import classes from '../Dashboard.module.scss';

const { RangePicker } = DatePicker;
const { Option } = Select;

const FiltersContainer: FC = () => {
  const datePickerRef = useRef(null);
  const project = useSelector(selectAuthUserInfo);
  const [selectedOption, setSelectedOption] = useState(
    project?.meta?.currentProject?.projectCurrency[0],
  );

  const dispatch = useDispatch<AppDispatch>();

  const storedButton = localStorage.getItem('activeButton');
  localStorage.removeItem('activeButton');

  const datePickerData = localStorage.getItem('datePickerData');
  localStorage.removeItem('datePickerData');

  const [activeButton, setActiveButton] = useState(
    !datePickerData && (storedButton || 'yesterday'),
  );
  const isSidebarOpen = useSelector(selectIsMenuSidebarOpen);
  const [localDate, setLocalDate] = useState<[string, string]>(['', '']);
  const [lastChosenDateRange, setLastChosenDateRange] = useState<any>([
    null,
    null,
  ]);

  const checkDateRange = (key: string) => {
    let from;
    let to;

    if (key === 'last24Hours') {
      const now = moment();
      const twentyFourHoursAgo = now.clone().subtract(24, 'hours');
      to = moment();
      from = twentyFourHoursAgo.subtract(1, 'seconds');
    } else if (key === 'today') {
      from = moment().startOf('day');
      to = moment();
    } else if (key === 'yesterday') {
      const yesterday = moment().subtract(1, 'days');
      const startOfYesterday = yesterday.clone().startOf('day');
      const startOfToday = moment().startOf('day');
      from = startOfYesterday;
      to = startOfToday;
    } else if (key === 'thisWeek') {
      from = moment().startOf('isoWeek');
      to = moment();
    } else if (key === 'lastWeek') {
      from = moment().subtract(1, 'weeks').startOf('isoWeek').startOf('day');
      to = moment()
        .subtract(1, 'weeks')
        .endOf('isoWeek')
        .startOf('day')
        .add(1, 'day');
    } else if (key === 'thisMonth') {
      from = moment().startOf('month');
      to = moment();
    } else if (key === 'lastMonth') {
      from = moment().subtract(1, 'months').startOf('month').startOf('day');
      to = moment()
        .subtract(1, 'months')
        .endOf('month')
        .startOf('day')
        .add(1, 'day');
    }

    return { from, to };
  };

  useEffect(() => {
    if (storedButton) {
      localStorage.removeItem('activeButton');
    }
    if (datePickerData && typeof datePickerData === 'string') {
      const parsedData = JSON.parse(datePickerData);
      const from = moment(parsedData.from);
      const to = moment(parsedData.to);
      setLocalDate([
        from.format('YYYY-MM-DD HH:mm'),
        to.format('YYYY-MM-DD HH:mm'),
      ]);
      setLastChosenDateRange([from, to]);
    }

    const { from, to } = activeButton
      ? checkDateRange(activeButton)
      : checkDateRange('');
    if (from && to) {
      setLocalDate([
        from.format('YYYY-MM-DD HH:mm'),
        to.format('YYYY-MM-DD HH:mm'),
      ]);
      setLastChosenDateRange([from, to]);
    }
  }, []);

  const handleButtonClick = (key: string) => {
    localStorage.removeItem('datePickerData');
    const { from, to } = checkDateRange(key);
    const requestData = {
      from: from?.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      to: to?.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      currency: project?.meta?.currentProject?.projectCurrency[0],
    };
    localStorage.setItem('activeButton', key);
    setActiveButton(key);
    dispatch(fetchAllData(requestData));
  };

  const handleDateChange = (date: any) => {
    if (!date) {
      localStorage.removeItem('activeButton');
      localStorage.removeItem('datePickerData');
      const today = moment();

      const todayFormatted = today.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      setLocalDate([todayFormatted, todayFormatted]);
      setLastChosenDateRange([today, today]);
    } else if (Array.isArray(date) && date.length === 2 && date[0] && date[1]) {
      localStorage.removeItem('activeButton');
      const requestData: any = {};
      requestData.from = date[0].format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      requestData.to = date[1].format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      requestData.currency = project?.meta?.currentProject?.projectCurrency[0];

      const fromFormatted = date[0].format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      const toFormatted = date[1].format('YYYY-MM-DDTHH:mm:ss.SSSZ');

      setLocalDate([fromFormatted, toFormatted]);
      setLastChosenDateRange([date[0], date[1]]);

      localStorage.setItem('datePickerData', JSON.stringify(requestData));
      dispatch(fetchAllData(requestData));
    }
  };

  useEffect(() => {
    if (
      selectedOption !== project?.meta?.currentProject?.projectCurrency[0] &&
      lastChosenDateRange[0] &&
      lastChosenDateRange[1]
    ) {
      dispatch(changeCurrency(selectedOption));
      const requestData = {
        from: lastChosenDateRange[0]?.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        to: lastChosenDateRange[1]?.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        currency: selectedOption,
      };
      dispatch(fetchAllData(requestData));
    }
  }, [selectedOption, lastChosenDateRange]);

  useEffect(() => {
    if (lastChosenDateRange && activeButton && !datePickerData) {
      localStorage.setItem('activeButton', activeButton);
    }
    if (lastChosenDateRange && !activeButton) {
      const requestData = {
        from: lastChosenDateRange[0]?.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        to: lastChosenDateRange[1]?.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      };
      localStorage.setItem('datePickerData', JSON.stringify(requestData));
    }
  }, [selectedOption]);

  return (
    <div
      className={`${
        isSidebarOpen ? classes.filtersContainerWrapperNavOpen : ''
      } ${classes.filtersContainerWrapper}`}>
      <Button
        className={
          activeButton === 'yesterday' ? classes.btnActive : classes.btn
        }
        type='primary'
        ghost
        onClick={() => handleButtonClick('yesterday')}>
        Yesterday
      </Button>
      <Button
        className={activeButton === 'today' ? classes.btnActive : classes.btn}
        type='primary'
        ghost
        onClick={() => handleButtonClick('today')}>
        Today
      </Button>
      <Button
        className={
          activeButton === 'last24Hours' ? classes.btnActive : classes.btn
        }
        type='primary'
        ghost
        onClick={() => handleButtonClick('last24Hours')}>
        Last 24 Hours
      </Button>
      <Button
        type='primary'
        ghost
        className={
          activeButton === 'thisWeek' ? classes.btnActive : classes.btn
        }
        onClick={() => handleButtonClick('thisWeek')}>
        This week
      </Button>
      <Button
        type='primary'
        ghost
        className={
          activeButton === 'lastWeek' ? classes.btnActive : classes.btn
        }
        onClick={() => handleButtonClick('lastWeek')}>
        Last week
      </Button>
      <Button
        type='primary'
        ghost
        className={
          activeButton === 'thisMonth' ? classes.btnActive : classes.btn
        }
        onClick={() => handleButtonClick('thisMonth')}>
        This Month
      </Button>
      <Button
        type='primary'
        ghost
        className={
          activeButton === 'lastMonth' ? classes.btnActive : classes.btn
        }
        onClick={() => handleButtonClick('lastMonth')}>
        Last Month
      </Button>
      <RangePicker
        className={`${classes.dataPicker} ${
          datePickerData ? classes.pickerFocused : ''
        }`}
        showTime
        onChange={handleDateChange}
        ref={datePickerRef}
        format='YYYY-MM-DD HH:mm'
        value={[dayjs(localDate[0].toString()), dayjs(localDate[1].toString())]}
        allowClear
      />
      <Select
        value={selectedOption}
        onChange={setSelectedOption}
        className={classes.currencySelect}>
        {project?.meta?.currentProject?.projectCurrency?.map(
          (currency: any) => (
            <Option key={currency} value={currency}>
              {currency}
            </Option>
          ),
        )}
      </Select>
    </div>
  );
};

export default FiltersContainer;
