import React, { FC, useEffect, useState } from 'react';
import { Select, Button, Table, Space, Modal } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'redux/store.types';
import {
  deletePageSeoData,
  fetchPageSeoData,
  handleUpdateOrCreate,
} from 'redux/reducers/seo/seo.thunks';
import {
  fetchProjectList,
  sendProjectId,
} from 'redux/reducers/projects/project.thunks';
import {
  selectAuthUserInfo,
  setCurrentProject,
} from 'redux/reducers/serverConfigs/serverConfigs.slice';
import ProjectDrawer from 'components/Common/Projects/ProjectDrawer';
import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';

import { columns } from './config';
import SEOEditModal from './SEOEditModal';

const { Option } = Select;

const SEO: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const pageSeoData = useSelector((state: RootState) => state.seoPage.data);

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const loading = useSelector((state: RootState) => state.seoPage.loading);
  const project = useSelector(selectAuthUserInfo);
  const [showDrawer, setShowDrawer] = useState(
    !project.meta.currentProject?.project,
  );

  const [confirmModalVisible, setConfirmModalVisible] =
    useState<boolean>(false);
  const [deletingItem, setDeletingItem] = useState<any>(null);

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  useEffect(() => {
    dispatch(fetchProjectList());
    if (!showDrawer) {
      dispatch(fetchPageSeoData());
    }
  }, []);

  const handleLanguageChange = (value: string) => {
    console.log(value);
    // Handle language selection logic here
  };

  const handleDeleteButtonClick = (record: any) => {
    setDeletingItem(record);
    setConfirmModalVisible(true);
  };

  const handleConfirmModalCancel = () => {
    setConfirmModalVisible(false);
    setDeletingItem(null);
  };

  const handleConfirmModalOk = () => {
    dispatch(deletePageSeoData(deletingItem));
    setConfirmModalVisible(false);
    setDeletingItem(null);
  };

  const handleAddButtonClick = () => {
    setEditMode(false);
    setFormData({});
    setModalVisible(true);
  };

  const handleEditButtonClick = (record: any) => {
    setEditMode(true);
    setFormData(record);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleProjectSelect = async (projectId: number) => {
    if (projectId) {
      dispatch(sendProjectId({ projectId }))
        .unwrap()
        .then(responseData => {
          dispatch(setCurrentProject(responseData));
          dispatch(fetchPageSeoData());
        })
        .catch(error => {
          console.error('Error dispatching sendProjectId:', error);
        });
    }
    toggleDrawer();
  };

  const handleModalOk = (updatedData: any) => {
    dispatch(handleUpdateOrCreate(updatedData));
    setModalVisible(false);
  };

  const columnsAll = [
    ...columns,
    {
      title: 'Edit',
      key: 'edit',
      render: (_text: any, record: any) => (
        <Space size='middle'>
          <EditOutlined
            style={{ color: '#1677ff', cursor: 'pointer' }}
            onClick={() => handleEditButtonClick(record)}
          />
          <DeleteOutlined
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => handleDeleteButtonClick(record.id)}
          />
        </Space>
      ),
    },
  ];
  return (
    <div>
      <ProjectDrawer
        visible={showDrawer}
        onClose={closeDrawer}
        onProjectSelect={handleProjectSelect}
      />
      {loading ? (
        <GlobalLoader />
      ) : (
        <>
          <div>
            <Select
              defaultValue='all'
              style={{ width: 150, marginRight: 50 }}
              onChange={handleLanguageChange}>
              <Option value='all'>All Languages</Option>
              {/* Add more language options here if needed */}
            </Select>
            <Button type='primary' onClick={handleAddButtonClick}>
              Add
            </Button>
            <div style={{ marginTop: 50 }}>
              <Table
                columns={columnsAll}
                dataSource={pageSeoData}
                pagination={false}
              />
            </div>
            <SEOEditModal
              visible={modalVisible}
              editMode={editMode}
              formData={formData}
              onCancel={handleModalCancel}
              onOk={handleModalOk}
            />
            <Modal
              title='Confirm Deletion'
              visible={!!confirmModalVisible}
              onOk={handleConfirmModalOk}
              onCancel={handleConfirmModalCancel}>
              Are you sure you want to delete this item?
            </Modal>
          </div>
        </>
      )}
    </div>
  );
};

export default SEO;
