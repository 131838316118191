import { Card, Divider, Switch } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MailOutlined, SkinOutlined, UserOutlined } from '@ant-design/icons';

import { AppDispatch } from 'redux/store.types';
import {
  selectAuthUserInfo,
  selectIsTooltipeActive,
} from 'redux/reducers/serverConfigs/serverConfigs.slice';
import { updateTooltipeSettingsThunks } from 'redux/reducers/projects/project.thunks';

import classes from './UserInfo.module.scss';

enum UserRole {
  SUPER_ADMIN = 'Super admin',
  USER = 'User',
}

const UserInfo: FC = () => {
  const authUser = useSelector(selectAuthUserInfo);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const isTooltipeActive = useSelector(selectIsTooltipeActive);

  const handleTooltipToggle = (checked: boolean) => {
    dispatch(updateTooltipeSettingsThunks(checked));
  };

  return (
    <Card title={t('User Information')} className={classes.root}>
      <div className={classes.userInfoTextBody}>
        <UserOutlined />
        <span className={classes.userInfoTitle}>{t('Username')}:</span>
        <span> {authUser.username}</span>
      </div>
      <Divider />
      <div className={classes.userInfoTextBody}>
        <SkinOutlined />
        <span className={classes.userInfoTitle}>{t('Role')}:</span>
        <span> {UserRole[authUser.role]}</span>
      </div>
      <Divider />
      <div className={classes.userInfoTextBody}>
        <MailOutlined />
        <span className={classes.userInfoTitle}>{t('Email')}:</span>
        <span> {authUser.email}</span>
      </div>
      <Divider />
      <div className={classes.userInfoTextBody}>
        <span className={classes.userInfoTitle}>Active Tooltip:</span>
        <Switch checked={isTooltipeActive} onChange={handleTooltipToggle} />
      </div>
    </Card>
  );
};

export default UserInfo;
