/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Card, Select, Tooltip } from 'antd';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/store.types';
import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';
import { IPayment } from 'redux/reducers/dashboard/dashboard.types';
import { formatNumber } from 'components/App/app.hooks';

import classes from './DashboardMobile.module.scss';
import { TooltipeText, updateCategories } from '../config';
import { selectIsTooltipeActive } from 'redux/reducers/serverConfigs/serverConfigs.slice';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

interface CardPaymentsProps {
  data: IPayment;
}

const CardPaymentsComponent: React.FC<CardPaymentsProps> = ({ data }) => {
  const [section, setSection] = useState(updateCategories(data).category);
  const [selectedData, setSelectedData] = useState(updateCategories(data));
  const isLoading = useSelector<RootState, boolean>(
    state => state.dashboard.isLoading,
  );
  const isTooltipeActive = useSelector(selectIsTooltipeActive);

  useEffect(() => {
    setSection(data.category);
    setSelectedData(updateCategories(data));
  }, [data]);

  const renderCategoryOptions = (
    categoryData: IPayment,
    isNested: boolean = false,
  ): JSX.Element[] => {
    const options: JSX.Element[] = [];
    const renderOption = (category: IPayment) => (
      <Option
        value={category.category}
        key={category.key}
        style={isNested ? { paddingLeft: '30px' } : {}}>
        {category.category}
      </Option>
    );
    if (!isNested) {
      options.push(renderOption(categoryData));
    }
    for (const childCategory of categoryData.children || []) {
      options.push(renderOption(childCategory));
      options.push(...renderCategoryOptions(childCategory, true));
    }
    return options;
  };

  const getCategoryByValue = (
    categoryData: IPayment,
    value: string,
  ): IPayment | undefined => {
    const segments = value.split(' > ');

    const findCategory = (
      data: IPayment,
      segments: string[],
      parentCategory?: string,
    ): IPayment | undefined => {
      if (segments.length === 0) {
        return data;
      }
      const [segment, ...restSegments] = segments;
      if (
        data.category &&
        data.category.toLowerCase() === segment.toLowerCase()
      ) {
        return findCategory(
          data,
          restSegments,
          parentCategory || data.category,
        );
      }
      const matchingCategory = (data.children || []).find(
        childCategory =>
          childCategory.category &&
          childCategory.category.toLowerCase() === segment.toLowerCase(),
      );
      if (matchingCategory) {
        if (
          parentCategory &&
          parentCategory.toLowerCase() ===
            segments[segments.length - 2]?.toLowerCase()
        ) {
          return findCategory(matchingCategory, restSegments, parentCategory);
        }
      }
      for (const childCategory of data.children || []) {
        const foundCategory = findCategory(
          childCategory,
          [segment, ...restSegments],
          parentCategory,
        );
        if (foundCategory) {
          return foundCategory;
        }
      }
      return undefined;
    };
    return findCategory(categoryData, segments);
  };

  const handleDropdownChange = (value: string) => {
    const selectedCategory = getCategoryByValue(updateCategories(data), value);
    if (selectedCategory) {
      setSelectedData(selectedCategory);
    }
  };

  if (isLoading) {
    return <GlobalLoader />;
  }

  return (
    <Card
      title={
        <>
          {selectedData.category}
          <Select
            value={selectedData.category}
            onChange={value => handleDropdownChange(value)}
            className={classes.cardDropdown}>
            {renderCategoryOptions(updateCategories(data))}
          </Select>
        </>
      }>
      <div className={classes.cardContent}>
        <div
          className={classes.cardRightSide}
          style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ marginRight: '100%' }}>Amount: </span>
          <span style={{ marginRight: '100%' }}>Quantity: </span>
          <span>Avg Amount: </span>
          <span>Unique Users: </span>
          <span style={{ marginRight: '100%' }}>Commissions: </span>
          {selectedData.delta && (
            <span style={{ marginRight: '100%' }}>Delta: </span>
          )}
          {isTooltipeActive && selectedData.delta_total && (
            <Tooltip
              style={{
                cursor: 'pointer',
              }}
              title={TooltipeText.TotalDelta}
              placement='topLeft'>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                Delta Total:
                <InfoCircleOutlined style={{ fontSize: 15, marginLeft: 4 }} />
              </div>
            </Tooltip>
          )}
          {!isTooltipeActive && selectedData.delta_total && (
            <span style={{ marginRight: '9%' }}>Delta Total: </span>
          )}
        </div>
        <div className={classes.cardLeftSide}>
          <span>{formatNumber(selectedData.amount)}</span>
          <span>{formatNumber(selectedData.quantity)}</span>
          <span>{formatNumber(selectedData.average_amount)}</span>
          <span>{formatNumber(selectedData.unique_users)}</span>
          <span>{formatNumber(selectedData.commissions)}</span>
          {selectedData.delta && <span>{selectedData.delta}</span>}
          {selectedData.delta_total && <span>{selectedData.delta_total}</span>}
        </div>
      </div>
    </Card>
  );
};

export default CardPaymentsComponent;
