import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'antd';

import { RootState } from 'redux/store.types';
import { IFinance } from 'redux/reducers/dashboard/dashboard.types';
import { selectFinanceData } from 'redux/reducers/dashboard/dashboard.slice';

import classes from '../Dashboard.module.scss';

import CardFinanceComponent from './CardFinanceComponent';

const MobileFinance: FC = () => {
  const financeData = useSelector<RootState, IFinance[]>(selectFinanceData);

  const responsiveConfig = {
    xs: 24, // Extra small screen size (less than 576px)
    sm: 12, // Small screen size (576px - 768px)
    md: 10, // Medium screen size (768px - 992px)
    lg: 6, // Large screen size (992px - 1200px)
    xl: 4, // Extra large screen size (1200px and above)
  };

  return (
    <div className={classes.finance}>
      {financeData.map((data, i) => (
        <Col
          className={classes.cardCol}
          {...responsiveConfig}
          key={i /* eslint-disable-line react/no-array-index-key */}>
          <CardFinanceComponent data={data} />
        </Col>
      ))}
    </div>
  );
};

export default MobileFinance;
