import React, { useState } from 'react';
import { Table, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { IPayment } from 'redux/reducers/dashboard/dashboard.types';
import { formatNumber } from 'components/App/app.hooks';
import { selectIsTooltipeActive } from 'redux/reducers/serverConfigs/serverConfigs.slice';

import classes from '../Dashboard.module.scss';
import { TooltipeText } from '../config';

interface PaymentsInfoProps {
  dataSource: IPayment[];
}

const PaymentsInfo: React.FC<PaymentsInfoProps> = ({ dataSource }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState('');
  const isTooltipeActive = useSelector(selectIsTooltipeActive);

  const handleClick = (record: IPayment) => {
    setIsOpen(!isOpen);
    console.log(selectedItemName);
    setSelectedItemName(record.category);
  };

  const columns = [
    {
      title: '',
      dataIndex: 'category',
      key: 'category',
      render: (text: any, record: IPayment) => {
        if (record?.children && record?.children.length > 0) {
          return <span>{record.category}</span>;
        }
        return <span>{text}</span>;
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a: IPayment, b: IPayment): number => {
        // if (!a.children && !b.children) {
        if (a.category !== 'Withdrawals' && b.category !== 'Deposits') {
          return b.amount - a.amount;
        }
        return 0;
      },
      render: (text: any, record: IPayment) => {
        if (record.children && record.children.length > 0) {
          const sortedChildren = [...record.children];
          sortedChildren.sort((a: IPayment, b: IPayment) => {
            return b.amount - a.amount;
          });
          return (
            <>
              <span>{formatNumber(record.amount)}</span>
            </>
          );
        }
        console.log(text);
        return <span>{formatNumber(record.amount)}</span>;
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      sorter: (a: IPayment, b: IPayment): number => {
        // if (!a.children && !b.children) {
        if (a.category !== 'Withdrawals' && b.category !== 'Deposits') {
          return b.quantity - a.quantity;
        }
        return 0;
      },
      render: (text: any, record: IPayment) => {
        if (record.children && record.children.length > 0) {
          const sortedChildren = [...record.children];
          sortedChildren.sort((a: IPayment, b: IPayment) => {
            return b.quantity - a.quantity;
          });
          return (
            <>
              <span>{formatNumber(record.quantity)}</span>
            </>
          );
        }
        console.log(text);
        return <span>{formatNumber(record.quantity)}</span>;
      },
    },
    {
      title: 'Avg Amount',
      dataIndex: 'average_amount',
      key: 'average_amount',
      sorter: (a: IPayment, b: IPayment): number => {
        // if (!a.children && !b.children) {
        if (a.category !== 'Withdrawals' && b.category !== 'Deposits') {
          return b.average_amount - a.average_amount;
        }
        return 0;
      },
      render: (text: any, record: IPayment) => {
        if (record.children && record.children.length > 0) {
          const sortedChildren = [...record.children];
          sortedChildren.sort((a: IPayment, b: IPayment) => {
            return b.average_amount - a.average_amount;
          });
          return (
            <>
              <span>{formatNumber(record.average_amount)}</span>
            </>
          );
        }
        console.log(text);
        return <span>{formatNumber(record.average_amount)}</span>;
      },
    },
    {
      title: 'Unique Users',
      dataIndex: 'unique_users',
      key: 'unique_users',
      sorter: (a: IPayment, b: IPayment): number => {
        // if (!a.children && !b.children) {
        if (a.category !== 'Withdrawals' && b.category !== 'Deposits') {
          return b.unique_users - a.unique_users;
        }
        return 0;
      },
      render: (text: any, record: IPayment) => {
        if (record.children && record.children.length > 0) {
          const sortedChildren = [...record.children];
          sortedChildren.sort((a: IPayment, b: IPayment) => {
            return b.unique_users - a.unique_users;
          });
          return (
            <>
              <span>{formatNumber(record.unique_users)}</span>
            </>
          );
        }
        console.log(text);
        return <span>{formatNumber(record.unique_users)}</span>;
      },
    },
    {
      title: 'Commissions',
      dataIndex: 'commissions',
      key: 'commissions',
      sorter: (a: IPayment, b: IPayment): number => {
        // if (!a.children && !b.children) {
        if (a.category !== 'Withdrawals' && b.category !== 'Deposits') {
          return b.commissions - a.commissions;
        }
        return 0;
      },
      render: (text: any, record: IPayment) => {
        if (record.children && record.children.length > 0) {
          const sortedChildren = [...record.children];
          sortedChildren.sort((a: IPayment, b: IPayment) => {
            return b.commissions - a.commissions;
          });
          return (
            <>
              <span>{formatNumber(record.commissions)}</span>
            </>
          );
        }
        console.log(text);
        return <span>{formatNumber(record.commissions)}</span>;
      },
    },
    {
      title: 'Delta',
      dataIndex: 'delta',
      key: 'delta',
      sorter: (a: IPayment, b: IPayment) => {
        if (a.delta && b.delta) {
          const regexPattern = /(\d+\.\d+)%/;
          const matchA = a.delta.match(regexPattern);
          const matchB = b.delta.match(regexPattern);

          if (matchA && matchA.length > 1 && matchB && matchB.length > 1) {
            const valueA = parseFloat(matchA[1]);
            const valueB = parseFloat(matchB[1]);

            return valueB - valueA;
          }
        }
        return 0;
      },
      render: (text: any, record: any) => {
        console.log(text);
        return <span>{record.delta}</span>;
      },
    },
    {
      title: (
        <>
          {isTooltipeActive && (
            <Tooltip
              style={{
                cursor: 'pointer',
              }}
              title={TooltipeText.TotalDelta}
              placement='topLeft'>
              <span>Total Delta</span>
              <span>
                <InfoCircleOutlined style={{ fontSize: 15, marginLeft: 10 }} />
              </span>
            </Tooltip>
          )}
        </>
      ),
      key: 'delta_total',
      dataIndex: 'delta_total',
      render: (...p: any) => {
        if (p[1].children !== undefined && p[2] === 0) {
          if (p[1].category === 'Deposits' || p[1].category === 'Withdrawals') {
            return {
              children: p[1].delta_total,
              props: {
                rowSpan: 9999,
              },
            };
          }
        }
        return {
          props: {
            rowSpan: 0,
          },
        };
      },
    },
  ];

  return (
    <>
      <h1 className={classes.infoTitle}>Payments</h1>
      <div className={classes.financicalInfoSection}>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          expandable={{
            onExpand: (record: any) => handleClick(record),
          }}
        />
      </div>
    </>
  );
};

export default PaymentsInfo;
