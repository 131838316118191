import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'antd';

import { RootState } from 'redux/store.types';
import { IPayment } from 'redux/reducers/dashboard/dashboard.types';
import { selectPaymentsData } from 'redux/reducers/dashboard/dashboard.slice';

import classes from '../Dashboard.module.scss';

import CardPaymentsComponent from './CardPaymentsComponent';

const MobilePayments: FC = () => {
  const paymentData = useSelector<RootState, IPayment[]>(selectPaymentsData);

  const responsiveConfig = {
    xs: 24, // Extra small screen size (less than 576px)
    sm: 12, // Small screen size (576px - 768px)
    md: 8, // Medium screen size (768px - 992px)
    lg: 6, // Large screen size (992px - 1200px)
    xl: 4, // Extra large screen size (1200px and above)
  };

  return (
    <div className={classes.finance}>
      {paymentData.map((data, i) => (
        <Col
          className={classes.cardCol}
          {...responsiveConfig}
          key={i /* eslint-disable-line react/no-array-index-key */}>
          <CardPaymentsComponent data={data} />
        </Col>
      ))}
    </div>
  );
};

export default MobilePayments;
