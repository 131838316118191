import React, { useState } from 'react';
import { Table, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { IFinance } from 'redux/reducers/dashboard/dashboard.types';
import { formatNumber } from 'components/App/app.hooks';
import { selectIsTooltipeActive } from 'redux/reducers/serverConfigs/serverConfigs.slice';

import classes from '../Dashboard.module.scss';
import { TooltipeText } from '../config';

interface FinanceInfoProps {
  dataSource: IFinance[];
}

const FinanceInfo: React.FC<FinanceInfoProps> = ({ dataSource }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState('');
  const isTooltipeActive = useSelector(selectIsTooltipeActive);

  const sortedDataSource = [...dataSource].sort((a, b) => {
    if (a.category === 'Sport') return -1;
    if (b.category === 'Sport') return 1;
    return 0;
  });

  const handleClick = (record: IFinance) => {
    setIsOpen(!isOpen);
    setSelectedItemName(record.category);
  };

  const columns = [
    {
      title: '',
      dataIndex: 'category',
      key: 'category',
      render: (text: any, record: IFinance) => {
        if (record.children && record.children.length > 0) {
          return (
            <>
              <span>{record.category}</span>
              {isTooltipeActive && record.category === 'Casino' && (
                <Tooltip
                  style={{
                    cursor: 'pointer',
                  }}
                  title={TooltipeText.Casino}
                  placement='topLeft'>
                  <span>
                    <InfoCircleOutlined
                      style={{ fontSize: 15, marginLeft: 10 }}
                    />
                  </span>
                </Tooltip>
              )}
            </>
          );
        }
        return <span>{text}</span>;
      },
    },
    {
      title: 'Bet',
      dataIndex: 'bets',
      key: 'bets',
      render: (text: any, record: IFinance) => {
        if (record.children && record.children.length > 0) {
          return <span>{formatNumber(record.bets)}</span>;
        }
        const titleWithTooltipe =
          record.category === 'Money' || record.category === 'Unit';

        return (
          <>
            <span>{formatNumber(text)}</span>
            {isTooltipeActive && titleWithTooltipe && (
              <Tooltip
                style={{
                  cursor: 'pointer',
                }}
                title={
                  record.category === 'Money'
                    ? TooltipeText.BetMoney
                    : TooltipeText.BetUnit
                }
                placement='topLeft'>
                <span>
                  <InfoCircleOutlined
                    style={{ fontSize: 15, marginLeft: 20 }}
                  />
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: 'Win',
      dataIndex: 'wins',
      key: 'wins',
      render: (text: any, record: IFinance) => {
        if (record.children && record.children.length > 0) {
          return <span>{formatNumber(record.wins)}</span>;
        }
        const titleWithTooltipe =
          record.category === 'Money' || record.category === 'Unit';

        return (
          <>
            <span>{formatNumber(text)}</span>
            {isTooltipeActive && titleWithTooltipe && (
              <Tooltip
                style={{
                  cursor: 'pointer',
                }}
                title={
                  record.category === 'Money'
                    ? TooltipeText.WinMony
                    : TooltipeText.WinUnit
                }
                placement='topLeft'>
                <span>
                  <InfoCircleOutlined
                    style={{ fontSize: 15, marginLeft: 20 }}
                  />
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: 'GGR',
      dataIndex: 'ggr',
      key: 'ggr',
      render: (text: any, record: IFinance) => {
        if (record.children && record.children.length > 0) {
          return <span>{formatNumber(record.ggr)}</span>;
        }
        return <span>{formatNumber(text)}</span>;
      },
    },
    {
      title: 'Unique Users',
      dataIndex: 'uniqueUsers',
      key: 'uniqueUsers',
      render: (text: any, record: IFinance) => {
        if (record.children && record.children.length > 0) {
          return <span>{formatNumber(record.uniqueUsers)}</span>;
        }
        return <span>{formatNumber(text)}</span>;
      },
    },
    {
      title: 'RTP',
      dataIndex: 'rtp',
      key: 'rtp',
      render: (text: any, record: IFinance) => {
        if (record.children && record.children.length > 0) {
          return <span>{`${formatNumber(record.rtp)}%`}</span>;
        }
        return <span>{`${formatNumber(text)}%`}</span>;
      },
    },
  ];
  const rowClassName = (record: any) => {
    if (
      record.children &&
      record.children.length > 0 &&
      record.category === selectedItemName
    ) {
      return `${classes.nestedTableBackground}`;
    }

    if (record.category === 'Total') {
      return `${classes.boldRow}`;
    }

    return '';
  };

  const castedRowClassName = rowClassName as (record: any) => string;

  return (
    <>
      <h1 className={classes.infoTitle}>Finance</h1>
      <div className={classes.financicalInfoSection}>
        <Table
          dataSource={sortedDataSource}
          columns={columns}
          pagination={false}
          expandable={{
            // showExpandColumn: false,
            // expandRowByClick: true,
            onExpand: (record: any) => handleClick(record),
          }}
          rowClassName={castedRowClassName}
        />
      </div>
    </>
  );
};

export default FinanceInfo;
