import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/store.types';

import { IFinance, IPayment, IGeneral } from './dashboard.types';
import { fetchAllData } from './dashboard.thunks';

interface IDashboardState {
  finance: IFinance[];
  payments: IPayment[];
  general: IGeneral;
  isLoading: boolean;
}

const initialState: IDashboardState = {
  finance: [],
  payments: [],
  general: {
    ggr: null,
    total_deposit: null,
    total_withdrawal: null,
    total_deposit_count: null,
    total_withdrawal_count: null,
    players_balance: null,
    visits: null,
    logins: null,
    registrations: null,
    activations: null,
    first_deposit_count: null,
    second_deposit_count: null,
  },
  isLoading: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setFinanceData: (state, action: PayloadAction<IFinance[]>) => {
      state.finance = action.payload;
    },
    setPaymentsData: (state, action: PayloadAction<IPayment[]>) => {
      state.payments = action.payload;
    },
    setGeneralData: (state, action: PayloadAction<IGeneral>) => {
      state.general = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllData.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchAllData.fulfilled, (state, action) => {
        const { finance, payments, general } = action.payload;
        state.finance = finance;
        state.payments = payments;
        state.general = general;
        state.isLoading = false;
      })
      .addCase(fetchAllData.rejected, state => {
        state.isLoading = false;
      });
  },
});

// ACTIONS
export const { setFinanceData, setPaymentsData, setGeneralData, setLoading } =
  dashboardSlice.actions;

// SELECTORS
export const selectFinanceData = (state: RootState) => state.dashboard.finance;
export const selectPaymentsData = (state: RootState) =>
  state.dashboard.payments;
export const selectGeneralData = (state: RootState) => state.dashboard.general;
export const selectLoading = (state: RootState) => state.dashboard.isLoading;

export default dashboardSlice.reducer;
