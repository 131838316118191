import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'redux/store.types';
import { setCurrentTimezone } from 'redux/reducers/serverConfigs/serverConfigs.slice';
import { updateTimezone } from 'redux/reducers/projects/project.thunks';
import { fetchAllData } from 'redux/reducers/dashboard/dashboard.thunks';
import { timezonesConfigs } from 'components/Routes/Dashboard/config';

const formatTimezone = (timezone: string) => {
  const offset = moment.tz(timezone).format('Z');
  return offset;
};

interface TimezoneProps {
  currentTimezone: string;
  currency: string;
}

const TimezoneSelector: React.FC<TimezoneProps> = ({
  currentTimezone,
  currency,
}) => {
  const [selectedTimezone, setSelectedTimezone] = useState<string | undefined>(
    currentTimezone || formatTimezone(moment.tz.guess()),
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleTimezoneChange = async (value: string) => {
    dispatch(updateTimezone(value))
      .unwrap()
      .then(() => {
        dispatch(setCurrentTimezone(value));
        dispatch(
          fetchAllData({
            currency,
          }),
        );
      })
      .catch(error => {
        console.error('Error dispatching sendProjectId:', error);
      });
  };

  useEffect(() => {
    if (currentTimezone !== selectedTimezone) {
      setSelectedTimezone(currentTimezone || formatTimezone(moment.tz.guess()));
    }
  }, [currentTimezone]);

  return (
    <>
      <Select
        showSearch
        placeholder='Select a timezone'
        optionFilterProp='children'
        onChange={handleTimezoneChange}
        value={selectedTimezone}
        style={{ marginRight: 5 }}>
        {timezonesConfigs.map(timezone => (
          <Select.Option key={timezone} value={timezone}>
            {`GMT${timezone}`}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default TimezoneSelector;
