/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Card, Select, Tooltip } from 'antd';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/store.types';
import GlobalLoader from 'components/Common/GlobalLoader/GlobalLoader';
import { IFinance } from 'redux/reducers/dashboard/dashboard.types';
import { formatNumber } from 'components/App/app.hooks';

import classes from './DashboardMobile.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TooltipeText } from '../config';
import { selectIsTooltipeActive } from 'redux/reducers/serverConfigs/serverConfigs.slice';

const { Option } = Select;

interface CardComponentProps {
  data: IFinance;
}

const CardFinanceComponent: React.FC<CardComponentProps> = ({ data }) => {
  const [selectedData, setSelectedData] = useState(data);
  const isLoading = useSelector<RootState, boolean>(
    state => state.dashboard.isLoading,
  );
  const isTooltipeActive = useSelector(selectIsTooltipeActive);

  useEffect(() => {
    setSelectedData(data);
  }, [data]);

  const renderCategoryOptions = (
    categoryData: IFinance,
    isNested: boolean = false,
  ) => {
    const options: JSX.Element[] = [];
    if (isNested) {
      options.push(
        <Option
          value={categoryData.category}
          key={categoryData.key}
          style={{ paddingLeft: '30px' }}>
          {categoryData.category}
        </Option>,
      );
    } else {
      options.push(
        <Option value={categoryData.category} key={categoryData.category}>
          {categoryData.category}
        </Option>,
      );
    }

    if (categoryData.children) {
      for (const childCategory of categoryData.children) {
        if (
          childCategory.category === 'Unit' ||
          childCategory.category === 'Money'
        ) {
          options.push(...renderCategoryOptions(childCategory, true));
        } else {
          options.push(...renderCategoryOptions(childCategory, false));
        }
      }
    }

    return options;
  };

  const handleDropdownChange = (value: string) => {
    const selectedCategory = getCategoryByValue(data, value);
    if (selectedCategory) {
      setSelectedData(selectedCategory);
    }
  };

  const getCategoryByValue = (
    categoryData: IFinance,
    value: string,
  ): IFinance | undefined => {
    if (categoryData.category === value) {
      return categoryData;
    }
    if (categoryData.children) {
      for (const childCategory of categoryData.children) {
        const selectedChild = getCategoryByValue(childCategory, value);
        if (selectedChild) {
          return selectedChild;
        }
      }
    }
    return undefined;
  };

  if (isLoading) {
    return <GlobalLoader />;
  }
  const titleWithTooltipe =
    selectedData.category === 'Money' || selectedData.category === 'Unit';

  return (
    <Card
      title={
        <>
          {selectedData.category}
          {isTooltipeActive && selectedData.category === 'Casino' && (
            <Tooltip
              style={{
                cursor: 'pointer',
              }}
              title={TooltipeText.Casino}
              placement='topLeft'>
              <span>
                <InfoCircleOutlined style={{ fontSize: 15, marginLeft: 20 }} />
              </span>
            </Tooltip>
          )}
          <Select
            value={selectedData.category}
            onChange={handleDropdownChange}
            className={classes.cardDropdown}>
            {renderCategoryOptions(data)}
          </Select>
        </>
      }>
      <div className={classes.cardContent}>
        {/* Display selected data here */}
        <div
          className={classes.cardRightSide}
          style={{ display: 'flex', flexDirection: 'column' }}>
          {isTooltipeActive && titleWithTooltipe && (
            <Tooltip
              style={{
                cursor: 'pointer',
              }}
              title={
                selectedData.category === 'Money'
                  ? TooltipeText.BetMoney
                  : TooltipeText.BetUnit
              }
              placement='topLeft'>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                Bets:
                <InfoCircleOutlined style={{ fontSize: 15, marginLeft: 4 }} />
              </div>
            </Tooltip>
          )}
          {!isTooltipeActive && !titleWithTooltipe && (
            <span style={{ marginRight: '100%' }}>Bets: </span>
          )}
          {isTooltipeActive && titleWithTooltipe && (
            <Tooltip
              style={{
                cursor: 'pointer',
              }}
              title={
                selectedData.category === 'Money'
                  ? TooltipeText.WinMony
                  : TooltipeText.WinUnit
              }
              placement='topLeft'>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                Wins:
                <InfoCircleOutlined style={{ fontSize: 15, marginLeft: 4 }} />
              </div>
            </Tooltip>
          )}
          {!isTooltipeActive && !titleWithTooltipe && (
            <span style={{ marginRight: '100%' }}>Wins: </span>
          )}
          <span style={{ marginRight: '100%' }}>GGR: </span>
          <span style={{ marginRight: '0%' }}>Unique Users: </span>
          <span style={{ marginRight: '100%' }}>Rtp: </span>
        </div>

        <div className={classes.cardLeftSide}>
          <span>{formatNumber(selectedData.bets)}</span>
          <span>{formatNumber(selectedData.wins)}</span>
          <span>{formatNumber(selectedData.ggr)}</span>
          <span>{formatNumber(selectedData.uniqueUsers)}</span>
          <span>{formatNumber(selectedData.rtp)}</span>
        </div>
      </div>
    </Card>
  );
};

export default CardFinanceComponent;
