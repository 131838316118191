import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';

import { AppDispatch } from 'redux/store.types';

import { IFinance, IPayment, IGeneral } from './dashboard.types';

export interface FetchDataResponse {
  finance: IFinance[];
  payments: IPayment[];
  general: IGeneral;
}

interface FetchDataRequestBody {
  type?: string[];
  from?: string;
  to?: string;
  currency: string;
}

const defaultType: string[] = ['general', 'payment', 'finance'];
const startOfYesterday = moment().subtract(1, 'days').startOf('day');
const startOfToday = moment().startOf('day');
const defaultFrom = startOfYesterday;
const defaultTo = startOfToday;

export const fetchAllData = createAsyncThunk<
  FetchDataResponse,
  FetchDataRequestBody | undefined,
  {
    dispatch: AppDispatch;
  }
>('data/fetchAll', async props => {
  const defData = { type: defaultType, from: defaultFrom, to: defaultTo };
  const defaultData = { ...defData, ...props };
  const requestData: FetchDataRequestBody = {
    ...defaultData,
    from: moment(defaultData.from).format('YYYY-MM-DDTHH:mm:ss'),
    to: moment(defaultData.to).format('YYYY-MM-DDTHH:mm:ss'),
    currency: defaultData.currency || '',
  };
  const url = '/statistics/statistics';
  try {
    const response = await axios.post(url, requestData);
    const { finance, payments, general } = response.data;
    return { finance, payments, general };
  } catch (error) {
    console.error('Error fetching data:', error);
    throw new Error('Failed to fetch data');
  }
});
