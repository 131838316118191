import { MenuProps } from 'antd';
import { ReactNode } from 'react';

export type MenuItem = Required<MenuProps>['items'][number];

export enum IAclPath {
  // ADD YOUR PROJECT ACL PATHS HERE
  home = 'home',
  settings = 'settings',
  setting_account = 'settings.account',
  reports = 'reports',
  reports_sports = 'reports.sports',
  reports_casino = 'reports.casino',
  admin = 'admin',
  admin_users_list = 'admin.users.list',
  crm_meta_data_list = 'crm-meta-data.list',
  statistics = 'statistics.statistics',
}

export enum IRoutePath {
  // ADD YOUR PROJECT PATHS HERE
  dashboard = '/',
  reports_sports = '/reports/sports',
  reports_casino = '/reports/casino',
  setting_account = '/setting/account',
  admin_for_users = '/admin/users',
  data_charts = '/data_chartsd',
  seo = '/seo',
  // seo_pages = '/seo/pages',
  seo_pages = '/',
}

export interface IMenuRoute {
  aclPath?: IAclPath; // protected if exist
  path?: IRoutePath;
  icon?: ReactNode;
  label?: string;
  element?: ReactNode;
  children?: IMenuRoute[];
}
