/* eslint-disable */
export const cardConfigData = [
  { title: 'GGR', key: 'ggr', priority: 1 },
  { title: 'Amount of Deposits', key: 'total_deposit', priority: 2 },
  { title: 'Amount of Withdrawals', key: 'total_withdrawal', priority: 4 },
  { title: 'Deposits Quantity', key: 'total_deposit_count', priority: 3 },
  { title: 'Withdrawals Quantity', key: 'total_withdrawal_count', priority: 5 },
  {
    title: 'Players Balance',
    key: 'players_balance',
    priority: 11,
    tooltipeText:
      "The total amount of money accessible in a user's account as of the query's end date.",
  },
  { title: 'Visitors', key: 'visits', priority: 12 },
  { title: 'Logged-in Players', key: 'logins', priority: 8 },
  { title: 'New Registrations', key: 'registrations', priority: 9 },
  {
    title: 'Activations',
    key: 'activations',
    priority: 10,
    tooltipeText:
      'The count of users who activated their accounts within the chosen time.',
  },
  {
    title: 'First Deposits',
    key: 'first_deposit_count',
    priority: 6,
    tooltipeText:
      'Count of users who made their first deposit within the chosen time,regardless of their registration data.',
  },
  {
    title: 'Second Deposits',
    key: 'second_deposit_count',
    priority: 7,
    tooltipeText:
      'Count of users who made their second deposit within the chosen time, regardless of their first deposit data.',
  },
];

export const TooltipeText = {
  GMT: 'All the reports are set according to the chosen timezone.',
  Dashboard:
    'The dashboard data is updated every hour and does not incorporate information from test accounts.',
  BetMoney:
    'Refers to all sorts of bets made using real money, with winnings received in either real money or units.',
  BetUnit:
    ' Includes all kinds of bets made using units, with winnings received in either real money or units.',
  WinMony: 'Represents wins  both as the unit and real money.',
  WinUnit: 'Represents wins both as the unit and real money.',
  Casino:
    'Includes all bets without site section (jackpot, test bets, games unavailable on the website, and others)  ',
  TotalDelta:
    'Calculates the difference between the total deposits (minus commissions for deposits) and the total withdrawals (minus commissions for withdrawals).    ',
};

export const obj = {
  general: {
    ggr: 6414153576,
    total_deposit: 383443221812,
    total_withdrawal: 295327489051,
    total_deposit_count: 30979375,
    total_withdrawal_count: 7652679,
    players_balance: 0,
    visits: 0,
    logins: 0,
    registrations: 0,
    activations: 0,
    first_deposit_count: '17064',
    second_deposit_count: '17037',
  },
  finance: [
    {
      key: 4,
      category: 'Sport',
      bets: '1028694540.33',
      wins: '651019134.30',
      ggr: '377675406.03',
      uniqueUsers: '1626',
      rtp: '63.29',
      children: [
        {
          key: 5,
          category: 'Cashbox',
          bets: '498939468.29',
          wins: '327742400.59',
          ggr: '171197067.70',
          uniqueUsers: '1',
          rtp: '65.69',
        },
        {
          key: 6,
          category: 'Website',
          bets: '529755072.04',
          wins: '323276733.71',
          ggr: '206478338.33',
          uniqueUsers: '626',
          rtp: '61.02',
        },
      ],
    },
    {
      key: 1,
      category: 'Casino',
      bets: '129612495882.66',
      wins: '123951062043.80',
      ggr: '5661433838.86',
      uniqueUsers: '4283',
      rtp: '95.63',
    },
    {
      key: 2,
      category: 'Live Casino',
      bets: '16318790259.87',
      wins: '15791042849.73',
      ggr: '527747410.14',
      uniqueUsers: '4278',
      rtp: '96.77',
    },
    {
      key: 3,
      category: 'Virtual Sport',
      bets: '1624668729.48',
      wins: '1499358904.13',
      ggr: '125309825.35',
      uniqueUsers: '4175',
      rtp: '92.29',
    },
    {
      key: 7,
      category: 'Games',
      bets: '2272866924.54',
      wins: '2138192905.52',
      ggr: '134674019.02',
      uniqueUsers: '4192',
      rtp: '94.07',
    },
    {
      key: 8,
      category: 'Total',
      bets: '150857516336.88',
      wins: '144030675837.48',
      ggr: 6826840499.400001,
      uniqueUsers: 0,
      rtp: '95.47',
    },
  ],
  payments: [
    {
      key: 1,
      category: 'Deposits',
      quantity: 92713951707982,
      amount: 383443221812,
      average_amount: '0.00',
      unique_users: 0,
      commissions: 0,
      delta_total: 88115732761,
      children: [
        {
          key: 50,
          category: 'TIGO',
          quantity: '032085476393624',
          amount: 141058857365,
          average_amount: '0.00',
          unique_users: 0,
          commissions: 0,
          delta: 12,
        },
        {
          key: 2,
          category: 'MPESA',
          quantity: '39387962420448',
          amount: 97731659417,
          average_amount: '0.00',
          unique_users: 0,
          commissions: 0,
          delta: 12,
        },
        {
          key: 3,
          category: 'BO',
          quantity: 99184,
          amount: 2643393557,
          average_amount: '26651.41',
          unique_users: 0,
          commissions: 0,
          delta: 12,
        },
        {
          key: 4,
          category: 'KIOSK',
          quantity: 1725567,
          amount: 6193048825,
          average_amount: '3588.99',
          unique_users: 0,
          commissions: 0,
          delta: 12,
        },
        {
          key: 5,
          category: 'HALOPESA',
          quantity: 5697101,
          amount: 47235454103,
          average_amount: '8291.14',
          unique_users: 0,
          commissions: 0,
          delta: 12,
        },
        {
          key: 6,
          category: 'AIRTEL',
          quantity: '21240505372058',
          amount: 88580808545,
          average_amount: '0.00',
          unique_users: 0,
          commissions: 0,
          delta: 12,
        },
      ],
    },
    {
      key: 2,
      category: 'Withdrawals',
      quantity: 287392947,
      amount: 295327489051,
      average_amount: '1027.61',
      unique_users: 0,
      commissions: 0,
      delta_total: 88115732761,
      children: [
        {
          key: 1,
          category: 'TIGO',
          quantity: '267264400',
          amount: 115175146966,
          average_amount: '430.94',
          unique_users: 0,
          commissions: 0,
          delta: 12,
        },
        {
          key: 2,
          category: 'MPESA',
          quantity: '16831680',
          amount: 75884583964,
          average_amount: '4508.44',
          unique_users: 0,
          commissions: 0,
          delta: 12,
        },
        {
          key: 3,
          category: 'BO',
          quantity: 591,
          amount: -103326033,
          average_amount: '-174832.54',
          unique_users: 0,
          commissions: 0,
          delta: 12,
        },
        {
          key: 4,
          category: 'KIOSK',
          quantity: 474817,
          amount: 5138287470,
          average_amount: '10821.62',
          unique_users: 0,
          commissions: 0,
          delta: 12,
        },
        {
          key: 5,
          category: 'HALOPESA',
          quantity: 1362257,
          amount: 31486074257,
          average_amount: '23113.17',
          unique_users: 0,
          commissions: 0,
          delta: 12,
        },
        {
          key: 6,
          category: 'AIRTEL',
          quantity: '01459202',
          amount: 67746722427,
          average_amount: '46427.24',
          unique_users: 0,
          commissions: 0,
          delta: 12,
        },
      ],
    },
  ],
};

export const timezonesConfigs = [
  '-12:00',
  '-11:00',
  '-10:00',
  '-09:00',
  '-08:00',
  '-07:00',
  '-06:00',
  '-05:00',
  '-04:00',
  '-03:00',
  '-02:00',
  '-01:00',
  '+00:00',
  '+01:00',
  '+02:00',
  '+03:00',
  '+04:00',
  '+05:00',
  '+06:00',
  '+07:00',
  '+08:00',
  '+09:00',
  '+10:00',
  '+11:00',
  '+12:00',
];

export function updateCategories(data: any): any {
  const updatedData: any = { ...data };

  if (updatedData.children) {
    updatedData.children = updatedData.children.map((child: any) => {
      const updatedChild = updateCategories(child);

      if (
        updatedChild.category === 'USSD' ||
        updatedChild.category === 'INSTANT'
      ) {
        updatedChild.category = `${updatedChild.category}/${updatedData.category}`;
      }

      return updatedChild;
    });
  }

  return updatedData;
}
