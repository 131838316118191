/* eslint-disable */
import React, { FC, useEffect, useRef, useState } from 'react';
import { Select, DatePicker } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'redux/store.types';
import { fetchAllData } from 'redux/reducers/dashboard/dashboard.thunks';
import {
  changeCurrency,
  selectAuthUserInfo,
  setCurrentTimezone,
} from 'redux/reducers/serverConfigs/serverConfigs.slice';

import classes from '../Dashboard.module.scss';
import { updateTimezone } from 'redux/reducers/projects/project.thunks';

const { RangePicker } = DatePicker;
const { Option } = Select;

const MobileFilters: FC = () => {
  const project = useSelector(selectAuthUserInfo);
  const [currencyOption, setCurrencyOption] = useState(
    project?.meta?.currentProject?.projectCurrency[0],
  );
  const datePickerRef = useRef(null);
  const dispatch = useDispatch<AppDispatch>();

  const [dateOption, setDateOption] = useState<any>('yesterday');
  const [selectedDateRange, setSelectedDateRange] = useState<any>([null, null]);
  const [lastChosenDate, setLastChosenDate] = useState<any>([null, null]);
  const [lastChosenDateRange, setLastChosenDateRange] = useState<any>([
    null,
    null,
  ]);

  const checkDateRange = (key: string) => {
    let from;
    let to;

    if (key === 'last24Hours') {
      const now = moment();
      const twentyFourHoursAgo = now.clone().subtract(24, 'hours');
      to = moment();
      from = twentyFourHoursAgo.subtract(1, 'seconds');
    } else if (key === 'today') {
      from = moment().startOf('day');
      to = moment();
    } else if (key === 'yesterday') {
      const yesterday = moment().subtract(1, 'days');
      const startOfYesterday = yesterday.clone().startOf('day');
      const startOfToday = moment().startOf('day');
      from = startOfYesterday;
      to = startOfToday;
    } else if (key === 'thisWeek') {
      from = moment().startOf('isoWeek');
      to = moment();
    } else if (key === 'lastWeek') {
      from = moment().subtract(1, 'weeks').startOf('isoWeek').startOf('day');
      to = moment()
        .subtract(1, 'weeks')
        .endOf('isoWeek')
        .startOf('day')
        .add(1, 'day');
    } else if (key === 'thisMonth') {
      from = moment().startOf('month');
      to = moment();
    } else if (key === 'lastMonth') {
      from = moment().subtract(1, 'months').startOf('month').startOf('day');
      to = moment()
        .subtract(1, 'months')
        .endOf('month')
        .startOf('day')
        .add(1, 'day');
    }

    return { from, to };
  };

  useEffect(() => {
    setCurrencyOption(project?.meta?.currentProject?.projectCurrency[0]);
  }, [project]);

  useEffect(() => {
    const fetchData = async () => {
      if (!project?.timezone && project?.meta.currentProject?.id) {
        try {
          const offsetMinutes = new Date().getTimezoneOffset();
          const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
          const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;
          const offsetFormatted = `${
            offsetHours < 10 ? '0' : ''
          }${offsetHours}:${
            offsetMinutesRemainder < 10 ? '0' : ''
          }${offsetMinutesRemainder}`;

          await dispatch(
            updateTimezone(
              offsetMinutes < 0 ? `+${offsetFormatted}` : `-${offsetFormatted}`,
            ),
          );
          dispatch(
            setCurrentTimezone(
              offsetMinutes < 0 ? `+${offsetFormatted}` : `-${offsetFormatted}`,
            ),
          );
        } catch (error) {
          console.error('Error fetching timezone:', error);
        }
      }
    };
    fetchData();
    if (dateOption) {
      if (project?.meta.currentProject?.id) {
        const { from, to } = checkDateRange(dateOption);
        const requestData = {
          from: from?.format('YYYY-MM-DDTHH:mm'),
          to: to?.format('YYYY-MM-DDTHH:mm'),
          currency: project?.meta?.currentProject?.projectCurrency[0],
        };

        dispatch(fetchAllData(requestData));
        setLastChosenDateRange([from, to]);
        setLastChosenDate([
          from?.format('YYYY-MM-DD HH:mm'),
          to?.format('YYYY-MM-DD HH:mm'),
        ]);
        setSelectedDateRange([null, null]);
      }
    }
  }, [dateOption]);

  const handleDateChange = (date: any) => {
    const requestData: any = {};
    setDateOption(null);
    requestData.from = date[0].format('YYYY-MM-DDTHH:mm');
    requestData.to = date[1].format('YYYY-MM-DDTHH:mm');
    requestData.currency = project?.meta?.currentProject?.projectCurrency[0];

    dispatch(fetchAllData(requestData));

    const localFromDate = date[0];
    const localToDate = date[1];

    setSelectedDateRange([localFromDate, localToDate]);
    setLastChosenDateRange([localFromDate, localToDate]);
    setLastChosenDate([localFromDate, localToDate]);
  };

  useEffect(() => {
    if (
      currencyOption !== project?.meta?.currentProject?.projectCurrency[0] &&
      lastChosenDateRange[0] &&
      lastChosenDateRange[1]
    ) {
      dispatch(changeCurrency(currencyOption));

      const requestData = {
        from: lastChosenDateRange[0]?.format('YYYY-MM-DDTHH:mm'),
        to: lastChosenDateRange[1]?.format('YYYY-MM-DDTHH:mm'),
        currency: currencyOption,
      };
      dispatch(fetchAllData(requestData));
    }
  }, [currencyOption, lastChosenDateRange]);

  const daterPickerStyle: any = {
    marginLeft: 'auto',
  };

  return (
    <>
      <div
        className={(classes.filtersContainer, classes.fullWidth)}
        style={{ display: 'flex', justifyContent: 'center' }}>
        <Select
          value={
            dateOption !== null ? dateOption : 'Date choosen from Datepicker'
          }
          onChange={setDateOption}
          className={classes.filtersDropDown}>
          <Option value='yesterday'>Yesterday</Option>
          <Option value='today'>Today</Option>
          <Option value='last24Hours'>Last 24 Hours</Option>
          <Option value='thisWeek'>This Week</Option>
          <Option value='lastWeek'>Last Week</Option>
          <Option value='thisMonth'>This Month</Option>
          <Option value='lastMonth'>Last Month</Option>
        </Select>
        <Select
          value={currencyOption}
          onChange={setCurrencyOption}
          className={classes.currencySelectMob}>
          {project?.meta?.currentProject?.projectCurrency?.map(
            (currency: any) => (
              <Option key={currency} value={currency}>
                {currency}
              </Option>
            ),
          )}
        </Select>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <RangePicker
          format='YYYY-MM-DD HH:mm'
          className={classes.dataPickerMob}
          onChange={handleDateChange}
          ref={datePickerRef}
          value={selectedDateRange}
          showTime
          style={daterPickerStyle}
        />
      </div>
    </>
  );
};

export default MobileFilters;
