/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

import { PageSeoData } from './types';
import { fetchPageSeoData } from './seo.thunks';

interface PageDataState {
  loading: boolean;
  data: PageSeoData[];
  error: string | null;
}

const initialState: PageDataState = {
  loading: false,
  data: [],
  error: null,
};

const pageSeoDataSlice = createSlice({
  name: 'pageSeoData',
  initialState,
  reducers: {
    deleteItem(state, action) {
      const idToDelete = action.payload;
      state.data = state.data.filter(item => item.id !== idToDelete);
    },
    addItem(state, action) {
      state.data.push(action.payload);
    },
    updateItem(state, action) {
      const updatedItem = action.payload;
      const index = state.data.findIndex(item => item.id === updatedItem.id);
      if (index !== -1) {
        state.data[index] = updatedItem;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPageSeoData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPageSeoData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchPageSeoData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'An error occurred';
      });
  },
});

export const { deleteItem, addItem, updateItem } = pageSeoDataSlice.actions;

export default pageSeoDataSlice.reducer;
