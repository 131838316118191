/* eslint-disable */

import { createAsyncThunk } from '@reduxjs/toolkit';

import { mockData } from 'components/Routes/SEO/config';
import { AppDispatch } from 'redux/store.types';

import { PageSeoData } from './types';
import { addItem, deleteItem, updateItem } from './seo.slice';
import axios from 'axios';

export const fetchPageSeoData = createAsyncThunk(
  'pageSeoData/fetchPageSeoData',
  async () => {
    try {
      const response = await axios.post('/crm-meta-data/list');
      return response.data;
    } catch (error) {
      // Handle error
      throw error;
    }
  },
);
export const deletePageSeoData =
  (id: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.post('/crm-meta-data/delete', { id });
      // Check if the status is 200
      if (response.status === 200) {
        dispatch(deleteItem(id));
      } else {
        // If not successful, throw an error
        throw new Error('Failed to delete item');
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

export const handleUpdateOrCreate =
  (updatedData: any) => async (dispatch: AppDispatch) => {
    try {
      // Preprocess updatedData to replace empty keys with empty strings
      const preprocessedData = preprocessData(updatedData);

      if (preprocessedData.id) {
        // Update existing item
        const response = await axios.post(
          '/crm-meta-data/update',
          preprocessedData,
        );
        dispatch(updateItem(updatedData));
      } else {
        // Create new item
        const response = await axios.post(
          '/crm-meta-data/insert',
          preprocessedData,
        );
        dispatch(addItem(response.data));
      }
    } catch (error) {
      console.error('Error updating or creating item:', error);
    }
  };

const preprocessData = (data: any) => {
  const processedData: any = {};
  for (const key in data) {
    processedData[key] =
      data[key] !== null && data[key] !== undefined ? data[key] : '';
  }
  return processedData;
};
