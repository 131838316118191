/* eslint-disable */

import React, { FC, useEffect, useState } from 'react';
import { Col, Input, Modal, Row, Select } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface Props {
  visible: boolean;
  editMode: boolean;
  formData: any;
  onCancel: () => void;
  onOk: (updatedData: any) => void;
}

const SEOEditModal: FC<Props> = ({
  visible,
  editMode,
  formData,
  onCancel,
  onOk,
}) => {
  const [localData, setLocalData] = useState<any>(formData || {});

  useEffect(() => {
    setLocalData(formData);
  }, [formData]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
  ) => {
    const { value } = e.target;
    setLocalData((prevData: any) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleFrequencyChange = (value: string) => {
    setLocalData((prevData: any) => ({
      ...prevData,
      frequency: value,
    }));
  };

  const handleCKEditorChange = (data: string, key: string) => {
    setLocalData((prevData: any) => ({
      ...prevData,
      [key]: data,
    }));
  };

  const handleOk = () => {
    onOk(localData);
  };

  return (
    <Modal
      title={editMode ? 'Edit SEO Data' : 'Add SEO Data'}
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
      width={1000}
      style={{ top: '0%' }}>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Input
              placeholder='URL'
              value={localData.url || ''}
              onChange={e => handleInputChange(e, 'url')}
            />
          </Col>
          <Col span={8}>
            <Input
              placeholder='Languages'
              value={localData.lang || ''}
              onChange={e => handleInputChange(e, 'lang')}
            />
          </Col>
          <Col span={8}>
            <Select
              value={localData.frequency || 'day'}
              onChange={handleFrequencyChange}
              style={{ width: '100%' }}>
              <Select.Option value='day'>Day</Select.Option>
              <Select.Option value='week'>Week</Select.Option>
              <Select.Option value='monthly'>Monthly</Select.Option>
            </Select>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
          <Col span={24}>
            <span style={{ display: 'block', marginBottom: '8px' }}>Title</span>
            <Input.TextArea
              placeholder='Title'
              style={{ width: '100%' }}
              value={localData.title || ''}
              onChange={(e: any) => handleInputChange(e, 'title')}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
          <Col span={24}>
            <span style={{ display: 'block', marginBottom: '8px' }}>
              Description
            </span>
            <Input.TextArea
              placeholder='Description'
              style={{ width: '100%' }}
              value={localData.description || ''}
              onChange={(e: any) => handleInputChange(e, 'description')}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
          <Col span={24}>
            <span style={{ display: 'block', marginBottom: '8px' }}>
              Keywords
            </span>
            <Input.TextArea
              placeholder='Keywords'
              style={{ width: '100%' }}
              value={localData.keywords || ''}
              onChange={(e: any) => handleInputChange(e, 'keywords')}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
          <Col span={24}>
            <span style={{ display: 'block', marginBottom: '8px' }}>
              Robots
            </span>
            <Input.TextArea
              placeholder='Robots'
              style={{ width: '100%' }}
              value={localData.robots || ''}
              onChange={(e: any) => handleInputChange(e, 'robots')}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
          <Col span={24}>
            <span style={{ display: 'block', marginBottom: '8px' }}>
              Canonical
            </span>
            <Input.TextArea
              placeholder='Canonical'
              style={{ width: '100%' }}
              value={localData.canonical || ''}
              onChange={(e: any) => handleInputChange(e, 'canonical')}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
          <Col span={24}>
            <span style={{ display: 'block', marginBottom: '8px' }}>h1</span>
            <CKEditor
              editor={ClassicEditor}
              data={localData.h1 || ''}
              onChange={(event, editor) =>
                handleCKEditorChange(editor.getData(), 'h1')
              }
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
          <Col span={24}>
            <span style={{ display: 'block', marginBottom: '8px' }}>
              Content
            </span>
            <CKEditor
              editor={ClassicEditor}
              data={localData.content || ''}
              onChange={(event, editor) =>
                handleCKEditorChange(editor.getData(), 'content')
              }
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default SEOEditModal;
