import {
  InfoCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Layout, Select, Tooltip, theme } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'redux/store.types';
import { IProject } from 'redux/reducers/projects/project.types';
import { fetchAllData } from 'redux/reducers/dashboard/dashboard.thunks';
import {
  selectAuthUserInfo,
  selectIsTooltipeActive,
  setCurrentProject,
} from 'redux/reducers/serverConfigs/serverConfigs.slice';
import { sendProjectId } from 'redux/reducers/projects/project.thunks';
import { TooltipeText } from 'components/Routes/Dashboard/config';

import { useIsMobile } from '../../../helpers/hooks.helpers';
import {
  selectIsMenuSidebarOpen,
  toggleMenuSidebar,
} from '../../../redux/reducers/appConfigs/appConfigs.slice';
import NrgLogo from '../NrgLogo/NrgLogo';

import classes from './Header.module.scss';
import TimezoneSelector from './components/TimezoneSelector';

const { Option } = Select;

const Header: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { token } = theme.useToken();
  const isMobile = useIsMobile();
  const isSidebarOpen = useSelector(selectIsMenuSidebarOpen);
  const TriggerIcon = isSidebarOpen ? MenuFoldOutlined : MenuUnfoldOutlined;
  const projectList = useSelector((state: RootState) => state.project);
  const project = useSelector(selectAuthUserInfo);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [, setIsSmallScreen] = useState(window.innerWidth <= 600);
  const isTooltipeActive = useSelector(selectIsTooltipeActive);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1000);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onTriggerClick = () => {
    dispatch(toggleMenuSidebar(!isSidebarOpen));
  };

  useEffect(() => {
    if (selectedProjectId) {
      dispatch(sendProjectId({ projectId: parseInt(selectedProjectId, 10) }))
        .unwrap()
        .then(responseData => {
          dispatch(setCurrentProject(responseData));
          dispatch(
            fetchAllData({
              currency: responseData.projectCurrency[0],
            }),
          );
        })
        .catch(error => {
          console.error('Error dispatching sendProjectId:', error);
        });
    }
  }, [selectedProjectId]);

  return (
    <Layout.Header
      style={{ background: token.colorBgContainer }}
      className={classes.root}>
      <div className={classes.headerActions}>
        <div className={classes.headerLeftActions}>
          <TriggerIcon className={classes.trigger} onClick={onTriggerClick} />
        </div>
        {isMobile && <NrgLogo />}
        <div className={classes.headerRightActions}>
          <div className={classes.container}>
            {isTooltipeActive && (
              <Tooltip
                className={classes.tooltipeHeadrIcon}
                title={TooltipeText.GMT}
                placement='right'>
                <span>
                  <InfoCircleOutlined style={{ marginRight: 8 }} />
                </span>
              </Tooltip>
            )}
            <TimezoneSelector
              currency={project.meta.currentProject?.projectCurrency[0]}
              currentTimezone={project.timezone}
            />
            <Select
              value={
                selectedProjectId === ''
                  ? project.meta.currentProject?.project
                  : selectedProjectId
              }
              onChange={setSelectedProjectId}
              className={classes.projectSelect}>
              {projectList.map((item: IProject) => (
                <Option key={item.id} value={item.id}>
                  {item.project}
                </Option>
              ))}
            </Select>
            {/* <NotificationTrigger isInSidebar={false} /> */}
          </div>
        </div>
      </div>
    </Layout.Header>
  );
};

export default Header;
