import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';

import { getValidRoutes } from '../Routes';
import {
  selectIsAuth,
  selectUserAcl,
} from '../../redux/reducers/serverConfigs/serverConfigs.slice';

const App: FC = () => {
  const isAuth = useSelector(selectIsAuth);
  const acl = useSelector(selectUserAcl);
  const routes = useRoutes(getValidRoutes(isAuth, acl));

  // Render the main app content once the data is loaded
  return routes;
};

export default App;
