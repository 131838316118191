import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'redux/store.types';

import { fetchProjectList } from './project.thunks';
import { IProject } from './project.types';

const initialState: any = [];

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjects: (action: PayloadAction<IProject[]>) => {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProjectList.fulfilled, (state, action) => {
      state = action.payload;
      return state;
    });
  },
});

// ACTIONS
export const { setProjects } = projectSlice.actions;

// SELECTORS
export const selectProjects = (state: RootState) => state.project;

export default projectSlice.reducer;
