import React, { FC } from 'react';

import { IProject } from 'redux/reducers/projects/project.types';

import classes from './Project.module.scss';

interface ProjectProps {
  data: IProject;
  onProjectSelect: () => void;
}

const Project: FC<ProjectProps> = ({ data, onProjectSelect }) => {
  const handleClick = () => {
    onProjectSelect();
  };

  return (
    <button className={classes.projectBtn} type='button' onClick={handleClick}>
      <h3>{data.project}</h3>
      {/* <p>{data.country}</p> */}
    </button>
  );
};

export default Project;
