export const columns = [
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
  },
  {
    title: 'Languages',
    dataIndex: 'lang',
    key: 'lang',
  },
  {
    title: 'Frequency',
    dataIndex: 'frequency',
    key: 'frequency',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Keywords',
    dataIndex: 'keywords',
    key: 'keywords',
  },
  {
    title: 'H1',
    dataIndex: 'h1',
    key: 'h1',
  },
];

export const mockData = [
  {
    id: 1,
    project_key: 'aaa',
    url: 'https://example.com/page1',
    lang: 'English',
    frequency: 'Weekly',
    title: 'Page Title 1',
    description: 'Page Description 1',
    keywords: 'keyword1, keyword2, keyword3',
    h1: 'Heading 1',
    robots: 'index, follow',
    canonical: 'https://example.com/page1',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    id: 2,
    project_key: 'aaa',
    url: 'https://example.com/page2',
    lang: 'Spanish',
    frequency: 'Monthly',
    title: 'Page Title 2',
    description: 'Page Description 2',
    keywords: 'keyword4, keyword5, keyword6',
    h1: 'Heading 2',
    robots: 'noindex, nofollow',
    canonical: 'https://example.com/page2',
    content:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  // Add more mock data as needed
];
