import GeneralInfo from '../DashboardSections/GeneralInfo';

import MobileFinance from './MobileFinance';
import MobilePayments from './MobilePayments';

export const tabsConfig = [
  {
    key: '1',
    label: 'General Information',
    children: <GeneralInfo isMobile />,
  },
  {
    key: '2',
    label: 'Finance',
    children: <MobileFinance />,
  },
  {
    key: '3',
    label: 'Payments',
    children: <MobilePayments />,
  },
];
